.Notable {
  background-color: #282c34;
  font-family: "Arial", sans-serif;
  color: #5fb9ffa7;
}

.Notable img {
  display: flex;
  border-radius: 2%;
  border: 2px solid #5fbaff;
  width: 50vh;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  flex-direction: row;
}

.Notable a {
  color: #5fbaff;
  font-size: 3vh;
}

.PhotoNotable {
  display: flex;

  justify-content: center;
  flex-direction: row;
}

.PhotoNotable img {
  margin: 2%;
}

.TextZoneNotable {
  padding: 2% 5% 5% 5%;
}

.TextZoneNotable b,
h2,
h1 {
  color: #5fbaff;
}

@media (max-width: 900px) {
  .PhotoNotable {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
