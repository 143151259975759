.table-container {
  margin: 20px;
  padding-top: 10vh;
  max-width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  color: #5fbaff;
}

th,
td {
  border: 1px solid #5fbaff;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #5fbaff;
  color: #282c34;
}

/* Effet de survol sur les lignes */
tr:hover {
  background-color: #5fbaff;
  cursor: pointer;
  color: #282c34;
}

tr.selected {
  background-color: #003a65; /* Couleur de fond lorsque sélectionné */
  color: white;
}

@media (max-width: 900px) {
  table th:nth-child(3),
  table th:nth-child(4),
  table th:nth-child(6),
  table td:nth-child(3),
  table td:nth-child(4),
  table td:nth-child(6) {
    display: none;
  }

  th,
  td {
    text-align: center;
    font-size: 1.5vh;
    padding: 5% 0% 5% 0%;
  }

  .table-container {
    margin: 10px;
    padding-top: 5vh;
  }
}
