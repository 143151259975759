.contacts-container {
    padding: 20px;
    font: 1.5em 'Arial', sans-serif;
    color: #5fbaff4d;
    text-align: center ; 

  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  .contact-info p {
    margin: 10px 0;

  }
  
  .contact-info a {
    color: #5fbaff;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    color: white;
  }
  