.Teaching {
  background-color: #282c34;
  font-family: "Arial", sans-serif;
  color: #5fb9ffa7;
}

.Teaching img {
  display: flex;
  border-radius: 2%;
  border: 2px solid #5fbaff;
  width: 50vh;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  flex-direction: row;
}

.Teaching a {
  color: #5fbaff;
  font-size: 3vh;
}

.PhotoTeaching {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 3vh;
  padding: 2% 5% 1% 5%;
}

.PhotoTeaching img {
  margin: 2%;
}

.TextZoneTeaching {
  padding: 2% 5% 5% 5%;
}

.TextZoneTeaching b,
h2,
h1 {
  color: #5fbaff;
}

.TextZoneTeaching p {
  text-align: left;
}

.TeachingList {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin: 10px;
}

.TeachingList ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.TeachingList li {
  margin: 5vh;
  font-size: 26px;
}

@media (max-width: 900px) {
  .PhotoTeaching {
    flex-direction: column;
  }
  .TextZoneTeaching p {
    text-align: center;
  }
  .TeachingList ul {
    text-align: center;
  }
}
