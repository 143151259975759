.projects-container {
    background-color: #282c34;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: flex-start; */
    gap: 50px;

}



.project-link {
    display: block;
    color: #0091ff;
    text-decoration: none;
    font-weight: bold;
}

.project-thumbnail {
    width: 50vh;
    background-color: #005ea709;
    border: 1px solid #0091ff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.569);
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.project-thumbnail:hover {
    box-shadow: 0 0 15px #0091ff;
    transition: border 0.5s;

}


.thumbnail-image-p {
    overflow: hidden;
    justify-content: center;
    padding: 20px;
}

.thumbnail-image-p img {
    width: 100%;
    height: 200px;
    object-fit: scale-down;
    border-radius: 5px 5px 0 0;
    margin-right: 10px;
    box-shadow: 0 0 30px #00000091;
}

.project-details {

    /* padding: 15px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.project-details p {
    color: #5fbaffa7;
    display: flex;
    margin: 0;
  
    margin-left: 5px;
    text-align: left;

}



h1 {
    margin-top: 10;
    color: #0091ff;
}

.project-link {
    margin-top: auto;
    margin-bottom: 5px;
    display: block;
    color: #0091ff;
    text-decoration: none;
    font-size: large;
}
.project-link:hover{
    display: block;
    color: white;

}

.lang {
    width: 5vh;
    height: 5vh;
    background-color: #0091ff19;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    margin-bottom: 30%;;
    justify-content: center;

}

@media (max-width: 900px) {
    .project-thumbnail {
        width: 80vw;

  }
}