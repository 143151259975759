.Introduction {
  background-color: #282c34;
  text-align: left;
  display: flex;
  height: 50vh;
  font-family: "Arial", sans-serif;
  font-size: 2vh;
  color: #5fb9ffa7;
  min-height: 100%;
  align-items: center;
  margin-top: 20vh;
}

.PhotoIntro {
  flex: 2;
  margin-right: 5%;
  margin-left: 10%;
}

.PhotoIntro img {
  border-radius: 2%;
  border: 2px solid #5fbaff;
}

.PhotoIntro img {
  max-width: 75%;
  max-height: 75%;
}

.TextZoneIntro {
  flex: 3;
  margin-right: 10%;

  size: 100vh;
}

.TextZoneIntro b,
h2 {
  color: #5fbaff;
}

@media (max-width: 1100px) {
  .Introduction {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5vh;
  }

  .TextZoneIntro {
    flex: 3;
    margin-right: 10%;
    margin-left: 10%;
    padding-bottom: 10%;
    size: 100vh;
    text-align: left;
  }

  .PhotoIntro {
    margin-left: 5%;
  }

  .PhotoIntro img {
    max-width: 100%;
    max-height: 100%;
  }
}
