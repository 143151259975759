.Resume {
  background-color: #282c34;
  text-align: center;
  display: flex;
  height: 100%;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: #0091ff;
  min-height: 100vh;
  flex-direction: row;
  justify-content: center;
}

.block-info {
  display: flex;
  flex-direction: column;
  margin: 5% 2.5% 5% 2.5%;
}

.PhotoResume {
  max-width: 100ch;
  margin-top: 5vh;
  padding-bottom: 10vh;
}

.PhotoResume img {
  height: 100%;
  width: 100%;
  border-radius: 2%;
  border: 2px solid #5fbaff;
}

.Resume button {
  display: inline-block;
  padding: 20px 40px;
  width: auto;
  background-color: #5fbaff;
  color: #282c34;
  border: none;
  cursor: pointer;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  width: auto;
  max-height: 100vh;
  font-size: 3vh;
  border-radius: 5px;
}

.Resume button:hover {
  background-color: #003a65;
  color: #5fbaff;
  cursor: pointer;
}

.Resume button:active {
  transform: translateY(3px);
  color: #00457a;
  background-color: #282c34;
}

@media (max-width: 1000px) {
  .Resume {
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 5vh;
  }
  .block-info {
    padding-top: 0;
    margin: 0;
  }

  .PhotoResume {
    margin: 5vh;
  }
}
