.skills-container {
  padding: 20px;

}

.skill-thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.skill-thumbnail {
  width: 20%;
  height: 200px;
  margin-bottom: 20px;
  background-color: #005ea709;
  border: 1px solid #5fbaff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.569);
}

.skill-thumbnail:hover {
  box-shadow: 0 0 15px #5fbaff;
  transition: border 0.5s;
}

.thumbnail-image-s {
  height: 50%;
  overflow: hidden;
  justify-content: center;
}

.thumbnail-image-s img {
  width: 100%;
  height: 80%;
  object-fit: scale-down;
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
}

.skill-details {
  height: 50%;
  /* padding: 10px; */
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skill-details h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #5fbaff;
}

.skill-details p {
  margin: 0;
  color: #5fbaffa7;
}

.level {
  display: flex;
  justify-content: left;

}

.xp {
  margin-right: 10px;
  display: flex;
  justify-content: left;
}

@media (max-width: 900px) {
  .skill-thumbnail {
    width: 45%;
  }
}